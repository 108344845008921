
  import Vue from 'vue';
  import {ROUTES} from "@/router/routes";
  import {COMPETITION_TYPE_IDS} from "@/utils/competition";

  export default Vue.extend({
    name: 'pwa-different-level-modal',
    props: {
      userHasLowerLevel: {
        type: Boolean,
        required: true,
      },
      userLevel: {
        type: Number,
        required: true,
      },
      competitionLevel: {
        type: Number,
        required: true,
      },
      gameAlias: {
        type: String,
        required: false,
      },
      isMinigame: {
        type: Boolean,
        required: false,
        default: false,
      },
      owner: {
        type: Object,
        required: false,
        default: null,
      },
      hasGameConfigured: {
        type: Boolean,
        required: false,
        default: false,
      },
      isCompetitionProposal: {
        type: Boolean,
        required: false,
        default: false,
      },
      competitionTypeId: {
        type: Number,
        required: false,
        default: COMPETITION_TYPE_IDS.WAGER,
      },
    },
    computed: {
      title(): string {
        return this.userHasLowerLevel ? this.$t('291') : this.$t('294');
      },
      message(): string {
        let text = '';
        if(this.userHasLowerLevel){
          if (this.competitionTypeId === COMPETITION_TYPE_IDS.MULTIPLAYER) {
            text = this.$t('678', {stars: (this.userLevel + 1)});
          } else if(this.userLevel > 0){
            text = this.$t('292', {stars: (this.userLevel + 1)})
          }else {
            if (this.competitionLevel < 4){
              text = this.$t('531');
            }else{
              text = this.$t('583');
            }
          }
        }else if (this.competitionTypeId === COMPETITION_TYPE_IDS.MULTIPLAYER) {
          text = this.$t('677', {stars: (this.userLevel + 1)});
        } else {
          text = this.$t('295', {stars: (this.userLevel + 1)});
        }

        return text;
      },
      acceptButtonText(): string {
        let text = '';
        if(this.userHasLowerLevel && this.allowPlay){
          text = this.$t('83');
        }else if(!this.userHasLowerLevel && ((this.competitionLevel == 0 && this.userLevel < 4) || (this.competitionLevel > 0)) && this.competitionTypeId !== COMPETITION_TYPE_IDS.MULTIPLAYER){
          text = this.$t('473')
        }

        return text;
      },
      allowPlay(): boolean {
        let allowPlay = false;
        if (this.competitionTypeId === COMPETITION_TYPE_IDS.MULTIPLAYER) {
          allowPlay = this.userHasLowerLevel;
        } else {
          allowPlay = this.userHasLowerLevel && ((this.userLevel == 0 && this.competitionLevel < 4) || (this.userLevel > 0));
        }

        return allowPlay;
      }
    },
    methods: {
      onHiddenModal() {
        if (!this.allowPlay) {
          this.$router.replace({name: this.getRouteName(), params: {lang: this.routeLang, gameAlias: this.gameAlias}});
        }
      },
      getRouteName() {
        if(this.isMinigame){
          return ROUTES.minigame.name;
        }else{
          return ROUTES.arena.name;
        }
      },
      rejectModal() {
        this.closeModal();
        if (this.isCompetitionProposal) {
          this.$emit('differentLevelRejected');
        } else {
          this.$router.replace({name: this.getRouteName(), params: {lang: this.routeLang, gameAlias: this.gameAlias}});
        }
      },
      acceptModal() {
        if (!this.isCompetitionProposal) {
          if (!this.userHasLowerLevel) {
            this.createMatch({
              gameAlias: this.gameAlias,
              gameConfigured: this.hasGameConfigured,
              competitionProposal: {username: this.owner.username},
            });
          }
        } else {
          this.$emit('differentLevelAccepted');
        }

        this.closeModal();
      },
      closeModal() {
        (this.$refs.modal as any).hide();
      },
    },
  });
