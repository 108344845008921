
  import Vue, {PropType} from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaUserLevel from "@/components/user/PwaUserLevel.vue";
  import {COMPETITION_STATUS_ID} from "@/utils/competition";
  import {ROUTES} from "@/router/routes";
  import {getUserGamesSettings} from "@/utils/user";
  import {GAMES_ALIAS} from "@/utils/game";
  import { MINIGAMES_ALIAS } from '@/utils/minigames';

  export default Vue.extend({
    name: "pwa-competition-players",
    components: {
      PwaIcon,
      PwaUserLevel,
    },
    props: {
      competitionStatusId: {
        type: Number,
        required: true,
      },
      isWager: {
        type: Boolean,
        required: true,
      },
      competitionGameAlias: {
        type: String,
        required: true,
      },
      isOwner: {
        type: Boolean,
        required: true,
      },
      isUserInCurrentMatch: {
        type: Boolean,
        required: true,
      },
      competitionResult: {
        type: Object as PropType<Record<string, any> | null>,
        required: false,
      },
      owner: {
        type: Object as PropType<Record<string, any> | null>,
        required: false,
      },
      competitionWinner: {
        type: Object,
        required: false,
      },
      competitionLoser: {
        type: Object,
        required: false,
      },
      winnerResult: {
        type: Number,
        required: false,
        default: null,
      },
      loserResult: {
        type: Number,
        required: false,
        default: null,
      },
      isDraw: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      isCompetitionPending(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.PENDING;
      },
      isCompetitionStarted(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.STARTED;
      },
      isCompetitionCancelled(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.CANCELLED;
      },
      isMysteryMinigame(): boolean{
        return this.competitionGameAlias == MINIGAMES_ALIAS.MYSTERY;
      }
    },
    methods: {
      goToUserProfile(username: string) {
        if (username && !this.isCompetitionStarted) {
          this.routerPush(ROUTES.profile.name, {username});
        }
      },
      getUserNamePath(username: string) {
        const routeLang = this.routeLang ? `/${this.routeLang}/` : '/';
        return username
          ? `${routeLang}${ROUTES.profile.path.replace(':username', encodeURIComponent(username))}`
          : '#';
      },
      getUserGamesSettings(userSettings: string): string[] {
        return getUserGamesSettings(userSettings);
      },
      isPlayerWinner(player: any): boolean {
        return this.competitionWinner && this.competitionWinner.user_id === player.user_id;
      },
      isPlayerLoser(player: any): boolean {
        return this.competitionLoser && this.competitionLoser.user_id === player.user_id;
      },
      canCopy(player: any): boolean {
        return navigator.clipboard && this.isUserInCurrentMatch && player.settings && player.user_id !== this.userId;
      },
      copyUsername(userGamesSettings: string[]) {
        const settings = userGamesSettings.length > 0 ? userGamesSettings[this.getGameSettingToCopyIndex()]: null;
        if (settings) {
          navigator.clipboard.writeText(settings)
            .then(() => {
              this.showToast(this.$t('519'), this.$t('520'));
            }).catch(() => {
            this.showToastError(this.$t('139'), this.$t('140'));
          });
        } else {
          this.showToastError(this.$t('139'), this.$t('140'));
        }
      },
      getGameSettingToCopyIndex(){
        let index = 0;
        if(this.competitionGameAlias == GAMES_ALIAS.COD_MOBILE){
          index = 1;
        }

        return index;
      }
    },
  });
