
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-competition-rules",
    components: {
      PwaAccordion: () => import("@/components/common/PwaAccordion.vue"),
    },
    props: {
      title: {
        type: String,
        required: false,
      },
      open: {
        type: Boolean,
        required: true,
      },
      isWager: {
        type: Boolean,
        required: true,
      },
      isOwner: {
        type: Boolean,
        required: true,
      },
      gfyScoreInformation: {
        type: Array,
        required: true,
      },
    },
    methods: {
      getGroupedRules() {
        let groupedRules = [];
        let numSlots = 2;
        let maxSlots = Math.round(this.gfyScoreInformation.length / numSlots);

        for (let i = 0; i < maxSlots; i++) {
          groupedRules.push(this.gfyScoreInformation.slice(i * numSlots, ((i+1) * numSlots)));
        }

        return groupedRules;
      }
    }
  });
