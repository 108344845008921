
  import Vue, {PropType} from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaUserLevel from "@/components/user/PwaUserLevel.vue";
  import {COMPETITION_STATUS_ID} from "@/utils/competition";
  import {ImageType} from "@/utils/form";
  import {getBrandFolderName} from "@/utils/common";
  import PwaCountdownMessage from "@/components/common/PwaCountdownMessage.vue";
  import {ROUTES} from "@/router/routes";

  const brandImageFolderName = getBrandFolderName();

  export default Vue.extend({
    name: "pwa-competition-result",
    data() {
      return {
        patchData: {} as Record<string, any>,
      };
    },
    props: {
      isManualResult: {
        type: Boolean,
        required: true,
      },
      buttonsDisabled: {
        type: Boolean,
        required: true,
      },
      showClaimResultButtons: {
        type: Boolean,
        required: true,
      },
      isUserWinner: {
        type: Boolean,
        required: true,
      },
      isUserLoser: {
        type: Boolean,
        required: true,
      },
      player1Id: {
        type: Number,
        required: true,
      },
      player2Id: {
        type: Number,
        required: true,
      },
      onPostCompetitionManualResult: {
        type: Function as PropType<(data: Record<string, any>) => void>,
        required: true,
      },
      competitionStatusId: {
        type: Number,
        required: true,
      },
      countDown: {
        type: String,
        required: false,
        default: null,
      },
      processingResult: {
        type: Boolean,
        required: true,
      },
      isUserInCurrentMatch: {
        type: Boolean,
        required: true,
      },
      competitionWinner: {
        type: Object,
        required: false,
      },
      competitionLoser: {
        type: Object,
        required: false,
      },
      isWager: {
        type: Boolean,
        required: false,
      },
      winnerResult: {
        type: Number,
        required: false,
        default: null,
      },
      loserResult: {
        type: Number,
        required: false,
        default: null,
      },
      isDraw: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    components: {
      PwaIcon,
      PwaUserLevel,
      ResultWinnerButtonBackground: () => import(`@/assets/img/${brandImageFolderName}/backgrounds/result-winner-button-background.svg?inline`),
      ResultLoserButtonBackground: () => import(`@/assets/img/${brandImageFolderName}/backgrounds/result-loser-button-background.svg?inline`),
      PwaCountdownMessage,
      PwaInputFile: () => import("@/components/PwaInputFile.vue"),
    },
    computed: {
      isCompetitionStarted(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.STARTED;
      },
      isCompetitionReview(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.REVIEW;
      },
      isCompetitionFinished(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.FINISHED;
      },
      competitionResultSubtitleMessage(): string {
        let message = '';
        if (this.isUserInCurrentMatch) {
          if (this.isManualResult) {
            if (this.isCompetitionReview) {
              message = '348';
            } else if (this.isCompetitionStarted) {
              if (this.processingResult) {
                if (this.isUserWinner) {
                  message = '355';
                } else {
                  message = '354';
                }
              } else if (this.showClaimResultButtons) {
                message = '346';
              } else if (this.isUserWinner) {
                message = '349';
              } else {
                message = '156';
              }
            }
          } else {
            if (this.isCompetitionReview || this.isCompetitionStarted) {
              message = '157';
            }
          }
        }
        return message;
      },
    },
    methods: {
      onWonButtonClick() {
        if (!this.buttonsDisabled) {
          const imageInput = this.$refs.imageInput as any;

          this.patchData = {
            result: 1,
          };
          this.showModal({
            title: this.$t('186'),
            message: this.$t('187'),
            onOkClick: () => imageInput.chooseFile(),
            onCancelClick: this.cleanPatchData,
          });
        }
      },
      onLostButtonClick() {
        if (!this.buttonsDisabled) {
          this.patchData = {};

          this.showModal({
            title: this.$t('188'),
            message: this.$t('189'),
            onOkClick: () => this.onPostCompetitionManualResult({result: 0}),
          });
        }
      },
      onAcceptDefeatClick() {
        if (this.showClaimResultButtons) {
          this.showModal({
            title: this.$t('190'),
            message: this.$t('191'),
            onOkClick: () => this.onPostCompetitionManualResult({result: 0}),
          });
        }
      },
      onNoAcceptDefeatClick() {
        if (this.showClaimResultButtons) {
          const imageInput = this.$refs.imageInput as any;
          this.patchData = {
            result: 1,
            'open_dispute': 1,
          };
          this.showModal({
            title: this.$t('192'),
            message: this.$t('193'),
            onOkClick: () => imageInput.chooseFile(),
            onCancelClick: this.cleanPatchData,
          });
        }
      },
      cleanPatchData() {
        this.patchData = {};
      },
      goToUserProfile(username: string) {
        if (username) {
          this.routerPush(ROUTES.profile.name, {username});
        }
      },
      getUserNamePath(username: string) {
        const routeLang = this.routeLang ? `/${this.routeLang}/` : '/';
        return username
          ? `${routeLang}${ROUTES.profile.path.replace(':username', encodeURIComponent(username))}`
          : '#';
      },
      onUploadImage(imageData: ImageType) {
        if (!this.buttonsDisabled || this.showClaimResultButtons) {
          const {image} = imageData;
          const data: any = {...this.patchData, image};

          this.onPostCompetitionManualResult(data);
        }
      },
    },
  });
