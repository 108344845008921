
    import Vue from 'vue';

    export default Vue.extend({
        name: "pwa-inscription-confirmation",
        props: {
            costInscription: {
                type: Number,
                required: true,
            },
            isCompetitionFreeInscription: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                loadContent: false,
            };
        },
        computed: {
            confirmationMessage(): string {
                const pricePrefix = this.isCompetitionFreeInscription ? `${this.$t('407')} ` : '';
                const price = `<span class="inscription-price">${pricePrefix}${this.formatPrice(this.costInscription)}</span>`;
                return this.$t('400', {price});
            },
        },
        methods: {
            onShow() {
                if (!this.loadContent) {
                    this.loadContent = true;
                }
            },
            acceptModal() {
                this.$emit('inscriptionConfirmation');
                this.closeModal();
            },
            closeModal() {
                (this.$refs.modal as any).hide();
            },
        },
    });
