
  import Vue from "vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import {getBrandFolderName} from "@/utils/common";

  const brandImageFolderName = getBrandFolderName();

  export default Vue.extend({
    name: "pwa-competition-header",
    props: {
      competition: {
        type: Object as any,
        required: true
      },
      userAward: {
        type: [String, Number],
        required: false,
        default: null,
      },
      competitionsFreeInscriptionsId: {
        type: Number,
        required: false,
        default: null,
      },
      isUserWinner: {
        type: Boolean,
        required: false,
        default: false,
      },
      isUserLoser: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    components: {
      PwaPrice,
      ResultWinnerTrophy: () => import(`@/assets/img/${brandImageFolderName}/backgrounds/result-winner-trophy.svg?inline`),
      ResultLoserTrophy: () => import(`@/assets/img/${brandImageFolderName}/backgrounds/result-loser-trophy.svg?inline`),
    },
  });
