export const getUserGamesSettings = (userSettings: string): string[] => {
  const settingsList: string[] = [];
  if (userSettings) {
    try {
      const settings = JSON.parse(userSettings);
      if (settings && settings.settings) {
        Object.keys(settings.settings).forEach((key: string) => {
          if (key !== 'label_id') {
            settingsList.push(settings.settings[key]);
          }
        });
      }
    } catch (e) {
      return settingsList;
    }
  }

  return settingsList;
};

export const USER_IDENTITY_VERIFICATION_STATUS_ID = {
  PENDING: 1,
  REJECTED: 2,
  APPROVED: 3,
};
