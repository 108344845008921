
  import Vue from 'vue';
  import PwaCompetition from "@/components/competitions/PwaCompetition.vue";
  import PwaDifferentLevelModal from "@/components/modals/competitions/PwaDifferentLevelModal.vue";
  import {
    COMPETITION_PROPOSAL_STATUS_ID,
    COMPETITION_STATUS_ID,
    COMPETITION_TYPE_IDS,
  } from "@/utils/competition";
  import {ROUTES} from "@/router/routes";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import {EVENTS} from "@/utils/events";
  import {ChatData} from "@/services/chat";

  export default Vue.extend({
    name: "Competition",
    mixins: [pageMixin],
    components: {
      PwaCompetition,
      PwaDifferentLevelModal,
    },
    props: {
      resultMatch: {
        type: Object,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        competition: {} as any,
        currentUserResult: null as any,
        userAward: null as string,
        gfyScore: {},
        chatData: null as ChatData,
        isUserInCompetition: null as boolean,
        roundResults: [],
        roundResultsPages: 1,
        currentMatch: null,
        dateRounds: null,
        currentRound: 1,
        userRound: 1,
        currentPageUser: 1,
        competitionFreeInscriptionAward: null,
        competitionsFreeInscriptionsId: null,
        userHasLowerLevel: false,
        showDifferentLevelMessageSeen: false,
        competitionProposalRematch: null,
        autoRefreshCompetitionPageTimeout: null,
        userLevel: 0,
        competitionLevel: 0
      }
    },
    beforeMount(): void {
      if (this.$route.name === ROUTES.competitionMatch.name && !this.resultMatch) {
        this.$router.push({name: ROUTES.competition.name, params: {lang: this.routeLang, competitionId: this.competitionId.toString()}, replace: true });
      }
    },
    mounted(): void {
      this.getCompetitionPage(true);
      this.$root.$on(EVENTS.RELOAD_COMPETITION_PAGE, () => this.getCompetitionPage(true));
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.RELOAD_COMPETITION_PAGE);
      this.clearAutoRefreshCompetitionPageTimeout();
    },
    computed: {
      competitionId(): number {
        return parseInt(this.$route.params.competitionId);
      },
      isMinigame(): boolean {
        const typeId = this.competition && this.competition.minigame ? this.competition.minigame : 0;
        return typeId === 1;
      },
      isWager(): boolean {
        const typeId = this.competition && this.competition.type_id ? this.competition.type_id : 0;
        return typeId === COMPETITION_TYPE_IDS.WAGER;
      },
      competitionStatusId(): number {
        return this.competition.status_id ? this.competition.status_id : 0;
      },
      isCompetitionPending(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.PENDING;
      },
      isCompetitionConfirmed(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.CONFIRMED;
      },
      isOwner(): boolean {
        const ownerId = this.competition && this.competition.owner ? this.competition.owner.user_id : 0;
        return ownerId == this.userId;
      },
      breadcrumb(): BreadcrumbItemType[] {
        return [
          BreadcrumbItems.lobby,
          {
            text: this.competition.game_name || '-',
            to: this.competition.game_alias ? {name: (this.isMinigame ? ROUTES.minigame.name : ROUTES.arena.name), params: {lang: this.routeLang, gameAlias: this.competition.game_alias}} : null,

          },
          {text: this.competition.region || '-'},
        ];
      },
      hasGameConfigured() {
        let isGameConfigured = false;
        if (this.competition.related_game_id) {
          const userGames = this.$store.state.user.games || [];
          isGameConfigured = Boolean(userGames.find(({game_id, validated}: any) => this.competition.related_game_id === game_id && validated === 1));
        }

        return isGameConfigured;
      },
    },
    methods: {
      async getCompetitionPage(showLoader = false) {
        if (showLoader) {
          this.showLoader(true);
        }
        try {
          const matchId = this.currentMatch && !this.isWager ? this.currentMatch.match_id : null;
          const params: Record<string, string|number> = {paginate: 1, matchId};
          const {data} = await this.$http.page.getCompetitionPage(this.competitionId, params);
          this.isUserInCompetition = data.data.is_user_in_competition === true;
          this.competition = data.data.competition || {};
          const currentUserResult = data.data.current_result_user || null;
          this.currentUserResult = Array.isArray(currentUserResult) && currentUserResult.length === 0 ? null : currentUserResult;
          this.userAward = data.data.userAward || null;
          this.gfyScore = data.data.gfy_score || {};
          this.chatData = data.data.chat_data || null;
          this.competitionProposalRematch = data.data.competition_proposal_rematch || null;
          this.dateRounds = data.data.date_rounds || [];
          this.userRound = data.data.current_round_user ? parseInt(data.data.current_round_user) : 1;
          const {results, pages}: any = data.data.competition_results;
          this.roundResults = results || [];
          this.roundResultsPages = parseInt(pages);
          this.currentRound = data.data.current_round_competition ? parseInt(data.data.current_round_competition) : 1;
          this.currentPageUser = data.data.current_page_user <= 1 ? 1 : data.data.current_page_user;
          this.competitionFreeInscriptionAward = data.data.competition_free_inscription_award || null;
          this.competitionsFreeInscriptionsId = data.data.competitions_free_inscriptions_id || null;

          if (this.isWager && !this.isCompetitionPending) {
            const match = this.roundResults[0] || {};
            if (!Array.isArray(match.player_1 || []) && !Array.isArray(match.player_2 || [])) {
              this.currentMatch = match;
            }
          }

          // Comprobación de nivel
          if (this.isLoggedIn && !this.showDifferentLevelMessageSeen && !this.isUserInCompetition && this.isWager && this.isCompetitionPending) {
            this.userLevel = data.data.user_level;
            this.competitionLevel = this.competition.owner.level;
            const matchmaking = this.competition.matchmaking !== 0;
            this.userHasLowerLevel = this.userLevel < this.competitionLevel;

            let showDifferentLevelMessage = false;
            if (this.userHasLowerLevel) {
              showDifferentLevelMessage = true;
            } else if (matchmaking && this.userLevel > this.competitionLevel) {
              showDifferentLevelMessage = true;
            }

            if (showDifferentLevelMessage) {
              const differentLevelModal: any = this.$refs.differentLevelModal;
              differentLevelModal.$refs.modal.show();
              this.showDifferentLevelMessageSeen = true;
            }
          }
          const competitionProposalRematchStatusId = this.competitionProposalRematch ? this.competitionProposalRematch.status_id : null;
          const isCompetitionProposalRematchRefreshStatus = competitionProposalRematchStatusId !== COMPETITION_PROPOSAL_STATUS_ID.ACCEPTED && competitionProposalRematchStatusId !== COMPETITION_PROPOSAL_STATUS_ID.REJECTED;
          if (this.isWager && this.isUserInCompetition && this.competitionStatusId === COMPETITION_STATUS_ID.FINISHED && isCompetitionProposalRematchRefreshStatus) {
            this.clearAutoRefreshCompetitionPageTimeout();
            this.autoRefreshCompetitionPageTimeout = setTimeout(() => {
              this.getCompetitionPage();
            }, 60000);
          }
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      clearAutoRefreshCompetitionPageTimeout(): void {
        if (this.autoRefreshCompetitionPageTimeout) {
          clearTimeout(this.autoRefreshCompetitionPageTimeout);
          this.autoRefreshCompetitionPageTimeout = null;
        }
      },
    },
    watch: {
      competitionId() {
        this.clearAutoRefreshCompetitionPageTimeout();
        this.getCompetitionPage(true);
      },
      resultMatch() {
        if (!this.isWager) {
          if (this.resultMatch) {
            this.currentMatch = this.resultMatch;
          } else {
            this.clearAutoRefreshCompetitionPageTimeout();
            this.currentMatch = null;
            this.getCompetitionPage(true);
          }
        }
      },
    }
  });
