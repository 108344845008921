
  import Vue from 'vue';
  import PwaPrice from "@/components/common/PwaPrice.vue";

  export default Vue.extend({
    name: 'pwa-create-match-success',
    components: {
      PwaPrice,
    },
    props: {
      gameName: {
        type: String,
        required: true,
      },
      platformName: {
        type: String,
        required: true,
      },
      regionName: {
        type: String,
        required: true,
      },
      award: {
        type: Number,
        required: true,
      },
      awardConverted: {
        type: Boolean,
        default: false,
        required: false,
      },
      costInscription: {
        type: Number,
        required: true,
      },
      isCreated: {
        type: Boolean,
        required: false,
        default: true,
      },
      isCompetitionFreeInscription: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  });
