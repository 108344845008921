
  import Vue, {PropType} from 'vue';
  import PwaIcon from '@/components/common/PwaIcon.vue';
  import UnityWebgl from 'unity-webgl';
  import { ROUTES } from '@/router/routes';
  import { EVENTS } from '@/utils/events';
  import { MODAL_IDS } from "@/utils/modal";
  import {
    getMinigameInstance,
    MINIGAMES_ALLOW_COMPETITION_ID,
    MINIGAMES_ALLOW_LANG,
    MINIGAMES_UNITY_CONTEXT
  } from "@/utils/minigames";
  import { LANGS } from '@/utils/i18n';

  export default Vue.extend({
    name: 'pwa-play-game',
    components: {
      PwaIcon,
      UnityVue: () => import('unity-webgl/vue'),
    },
    props: {
      isModal: {
        type: Boolean,
        required: false,
        default: false,
      },
      gameAlias: {
        type: String,
        required: true,
      },
      competitionId: {
        type: Number,
        required: true,
      },
      resultId: {
        type: Number,
        required: true,
      },
      numAttempt: {
        type: Number,
        required: false,
        default: 1,
      },
      winScore: {
        type: Number,
        required: false,
        default: null,
      },
      onEndMatch: {
        type: Function as PropType<(result: number, winScore: number) => void>,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        gameLoaded: false,
        finished: false,
        score: 0,
        unityContext: null as UnityWebgl,
        startLoadTime: null,
        endLoadTime: null,
      }
    },
    mounted() {
      this.initGame();
    },
    beforeDestroy() {
      this.clearMinigameInstance();
      if(this.isModal){
        this.$root.$emit(EVENTS.ON_PLAY_GAME_MODAL_CLOSE, {score: this.score, winScore: this.winScore});
      }
    },
    methods: {
      initGame() {
        this.startLoadTime = new Date();
        if(!this.gameAlias || !MINIGAMES_UNITY_CONTEXT[this.gameAlias]){
          this.showToastError(this.$t('139'), this.$t('552'));
          this.$router.push({name: ROUTES.lobby.name, params: {lang: this.routeLang}});
        }else {
          this.showLoader(true);
          this.unityContext = getMinigameInstance(this.gameAlias);

          this.unityContext.on('mounted', (unityContext) => {
            this.showLoader(false);
            if(MINIGAMES_ALLOW_COMPETITION_ID[this.gameAlias]){
              unityContext.send('GameManager', 'SetMatchId', this.competitionId + '_' + this.numAttempt);
            }
            unityContext.send('GameManager', 'SetUserId', this.$store.state.auth.user.id.toString());
            if(MINIGAMES_ALLOW_LANG[this.gameAlias]){
              unityContext.send('GameManager', 'ChangeLanguage', (this.currentLang == LANGS.EN ? 0 : 1));
            }
            this.gameLoaded = true;
            this.endLoadTime = new Date();
          });

          this.unityContext.on('SendMatchId', (newMatchId) => {
            const loadTime = (this.endLoadTime.getTime() - this.startLoadTime.getTime()) / 1000;
            const data = {
              'match_id': newMatchId,
              'load_time': loadTime
            };
            this.$http.competition.postMinigameResult(this.competitionId, this.resultId, data);
          });

          this.unityContext.on('EndMatch', (score) => {
            this.score = score;
            this.refreshResultMatch(score);
            if (this.onEndMatch) {
              this.onEndMatch(score, this.winScore);
            }
          });
        }
      },
      async refreshResultMatch(score: number) {
        return new Promise((resolve) => {
          setTimeout(() => {
            const data = {
              'result': score
            };
            this.$http.competition.postMinigameResult(this.competitionId, this.resultId, data);
            this.finished = true;
            resolve(true);
          }, 2000);
        });
      },
      onFinishClick() {
        this.clearMinigameInstance();
        if(this.isModal){
          this.hideModalById(MODAL_IDS.MODAL_PLAY_GAME);
        }else{
          this.$router.back();
        }
      },
      clearMinigameInstance() {
        if (this.unityContext) {
          try {
            this.unityContext.clear();
            this.unityContext.unload();
            this.unityContext = null;
          } catch (e) {
            this.unityContext = null;
          }
        }
      },
    }
  });
