
  import Vue, {PropType} from 'vue';
  import PwaCountdownMessage from "@/components/common/PwaCountdownMessage.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaCompetitionPlayers from "@/components/competitions/PwaCompetitionPlayers.vue";
  import PwaCompetitionResult from "@/components/competitions/PwaCompetitionResult.vue";
  import PwaCompetitionRules from "@/components/competitions/PwaCompetitionRules.vue";
  import PwaCompetitionHeader from "@/components/competitions/PwaCompetitionHeader.vue";
  import PwaInscriptionNoMoney from "@/components/modals/competitions/PwaInscriptionNoMoney.vue";
  import PwaInscriptionConfirmation from "@/components/modals/competitions/PwaInscriptionConfirmation.vue";
  import PwaCreateMatchSuccess from "@/components/modals/competitions/PwaCreateMatchSuccess.vue";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import {NotificationCenterActionTypes} from "@/store/modules/notificationCenter/actions";
  import {
    COMPETITION_PROPOSAL_STATUS_ID,
    COMPETITION_STATUS_ID,
    COMPETITION_TYPE_IDS,
    getPlatformIcon, RegisterCompetitionErrorKey
  } from "@/utils/competition";
  import {ChatData, ChatMessage} from "@/services/chat";
  import {ROUTES} from "@/router/routes";
  import { MODAL_IDS } from "@/utils/modal";
  import PwaPlayGame from "@/components/competitions/PwaPlayGame.vue";
  import {EVENTS} from "@/utils/events";
  import {showInterstitial} from "@/services/ads";

  export default Vue.extend({
    name: "pwa-competition",
    components: {
      PwaCountdownMessage,
      PwaIcon,
      PwaCompetitionPlayers,
      PwaCompetitionResult,
      PwaCompetitionRules,
      PwaCompetitionHeader,
      PwaInscriptionConfirmation,
      PwaChatContainer: () => import("@/components/chat/PwaChatContainer.vue"),
      PwaCompetitionAwards: () => import("@/components/competitions/PwaCompetitionAwards.vue"),
      PwaCompetitionRounds: () => import("@/components/competitions/PwaCompetitionRounds.vue"),
      PwaAcceptCompetitionProposalModal: () => import("@/components/modals/competitions/PwaAcceptCompetitionProposalModal.vue"),
      PwaWebAdBillboardBanner: () => import('@/components/ads/PwaWebAdBillboardBanner.vue'),
      PwaWebAdHalfPageBanner: () => import('@/components/ads/PwaWebAdHalfPageBanner.vue'),
    },
    props: {
      competition: {
        type: Object,
        required: true
      },
      isUserInCompetition: {
        type: Boolean,
        required: false,
      },
      roundResults: {
        type: Array,
        required: false,
        default: null,
      },
      dateRounds: {
        type: Array,
        required: false,
        default: null,
      },
      currentMatch: {
        type: Object,
        required: false,
        default: null,
      },
      currentRound: {
        type: Number,
        required: false,
        default: 1,
      },
      userRound: {
        type: Number,
        required: false,
        default: 1,
      },
      currentPageUser: {
        type: Number,
        required: false,
        default: 1,
      },
      gfyScore: {
        type: [Object, Array],
        required: true
      },
      userAward: {
        type: String,
        required: false,
        default: null,
      },
      chatData: {
        type: Object as PropType<ChatData>,
        required: false,
        default: null,
      },
      onReloadCompetitionData: {
        type: Function as PropType<() => void>,
        required: true,
      },
      competitionFreeInscriptionAward: {
        type: Number,
        required: false,
        default: null,
      },
      competitionsFreeInscriptionsId: {
        type: Number,
        required: false,
        default: null,
      },
      competitionProposalRematch: {
        type: Object,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        manualResult: null as any,
        minigameResult: null as any,
        termsAndConditionsAccepted: false,
        hasTimeLeft: true,
        forceDisabledButton: false,
        autoRefreshInterval: null,
        selectedTab: null,
        useCompetitionFreeInscription: 0,
        tabs: {AWARDS: 'AWARDS', ROUNDS: 'ROUNDS', RULES: 'RULES'},
      }
    },
    mounted(): void {
      this.restartAutoRefreshInterval();
      this.updateSelectedTab();
      this.$root.$on(EVENTS.ON_PLAY_GAME_MODAL_CLOSE, this.restartAutoRefreshInterval);
    },
    beforeDestroy(): void {
      this.clearAutoRefreshInterval();
      this.$root.$off(EVENTS.ON_PLAY_GAME_MODAL_CLOSE);
    },
    computed: {
      competitionId(): number {
        return this.competition ? parseInt(this.competition.id) : null;
      },
      isWager(): boolean {
        const typeId = this.competition && this.competition.type_id ? this.competition.type_id : 0;
        return typeId === COMPETITION_TYPE_IDS.WAGER;
      },
      isTournament(): boolean {
        const typeId = this.competition && this.competition.type_id ? this.competition.type_id : 0;
        return typeId === COMPETITION_TYPE_IDS.TOURNAMENT;
      },
      isOwner(): boolean {
        let isOwner = false;
        if (this.isWager) {
          const ownerId = this.competition && this.competition.owner ? this.competition.owner.user_id : 0;
          isOwner = ownerId == this.userId;
        }

        return isOwner;
      },
      player1(): Record<string, any> {
        return this.currentMatch && this.currentMatch.player_1 ? this.currentMatch.player_1 : {};
      },
      player2(): Record<string, any> {
        return this.currentMatch && this.currentMatch.player_2 ? this.currentMatch.player_2 : {};
      },
      isPlayer1(): boolean {
        let isPlayer1 = false;
        if(Object.keys(this.player1).length > 0){
          isPlayer1 = this.player1.user_id == this.userId;
        }
        return isPlayer1;
      },
      isPlayer2(): boolean {
        let isPlayer2 = false;
        if(Object.keys(this.player2).length > 0){
          isPlayer2 = this.player2.user_id == this.userId;
        }
        return isPlayer2;
      },
      competitionStatusId(): number {
        return this.competition.status_id ? this.competition.status_id : 0;
      },
      countDownMessage(): string {
        let message = '101';
        if (this.isCompetitionPending) {
          if (this.isWager) {
            message = '342';
          } else {
            message = this.isUserInCompetition ? '381' : '369';
          }
        } else if (this.isCompetitionConfirmed && !this.isWager) {
          message = this.isUserInCompetition ? '381' : '369';
        } else if (this.isCompetitionStarted) {
          if (this.showClaimResultButtons) {
            message = '344';
          } else if (this.hasManualResult) {
            message = '345';
          } else {
            message = '343';
          }
        }

        return message;
      },
      countDownDate(): string {
        let countDownDate = '';
        if (this.isManualResultCompetition && this.manualResult && this.manualResult.countDown) {
          countDownDate = this.manualResult.countDown;
        } else if (this.isCompetitionPending || this.isCompetitionConfirmed) {
          countDownDate = this.competition.start_date;
        } else {
          countDownDate = this.competition.end_date;
        }

        return countDownDate || '';
      },
      isManualResultCompetition(): boolean {
        return this.competition.manual === 1;
      },
      hasManualResult(): boolean {
        return this.manualResult && (this.manualResult.result_1 !== null || this.manualResult.result_2 !== null);
      },
      resultButtonsDisabled(): boolean {
        let disabled = false;

        if (!this.isCompetitionStarted) {
          disabled = true;
        } else if (!this.isManualResultCompetition) {
          disabled = true;
        } else if (!this.isUserInCurrentMatch) {
          disabled = true;
        } else if (this.hasManualResult) {
          disabled = true;
        }

        return disabled;
      },
      isCompetitionPending(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.PENDING;
      },
      isCompetitionCancelled(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.CANCELLED;
      },
      isCompetitionStarted(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.STARTED;
      },
      isCompetitionFinished(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.FINISHED;
      },
      isCompetitionReview(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.REVIEW;
      },
      isCompetitionConfirmed(): boolean {
        return this.competitionStatusId === COMPETITION_STATUS_ID.CONFIRMED;
      },
      showClaimResultButtons(): boolean {
        let showButtons = false;
        if (this.manualResult && this.isUserLoser && this.isCompetitionStarted && this.isUserInCurrentMatch) {
          if (this.isPlayer1) {
            showButtons = this.manualResult.result_2 !== null && this.manualResult.result_1 === null;
          } else if (this.isPlayer2) {
            showButtons = this.manualResult.result_1 !== null && this.manualResult.result_2 === null;
          }
        }
        return showButtons;
      },
      manualResultWinner(): Record<string, any> {
        let winner: Record<string, any> = {};
        if (this.manualResult && this.manualResult.winner) {
          winner = this.manualResult.winner === 'player_1' ? this.player1 : this.player2;
        }

        return winner;
      },
      isUserWinner(): boolean {
        let winner = false;
        if (this.isUserInCompetition) {
          const competitionWinner = this.currentMatch && this.currentMatch.winner ? this.currentMatch.winner : 0;
          if (competitionWinner) {
            winner = competitionWinner === this.userId;
          } else if (this.manualResult && this.manualResult.winner) {
            const winnerUserId: number = this.manualResultWinner.user_id;
            winner = winnerUserId == this.userId;
          }
        }

        return winner;
      },
      isUserLoser(): boolean {
        let loser = false;
        if (this.isUserInCompetition) {
          const competitionLoser = this.currentMatch && this.currentMatch.loser ? this.currentMatch.loser : 0;
          if (competitionLoser) {
            loser = competitionLoser === this.userId;
          } else if (this.manualResult && this.manualResult.winner) {
            const winnerUserId: number = this.manualResultWinner.user_id;
            loser = winnerUserId != this.userId;
          }
        }

        return loser;
      },
      chatMessages(): ChatMessage[] {
        let messages: ChatMessage[] = null;
        if (this.isCompetitionPending) {
          const message = this.isWager ? this.$t('145') : this.$t('368');
          const time = Date.now();
          messages = [{
            userName: 'Admin', userId: '', messageId: time.toString(), message, time, isImage: false
          }];
        }

        return messages;
      },
      isUserInCurrentMatch():boolean {
        return this.isPlayer1 || this.isPlayer2;
      },
      currentMatchId(): number {
        return this.currentMatch && this.currentMatch.match_id ? parseInt(this.currentMatch.match_id) : 0;
      },
      competitionWinner(): any {
        let winner = null;
        const competitionWinnerId = this.currentMatch && this.currentMatch.winner ? this.currentMatch.winner : 0;
        if (competitionWinnerId) {
          winner = this.player1.user_id === competitionWinnerId ? this.player1 : this.player2;
        }

        return winner;
      },
      competitionLoser(): any {
        let loser = null;
        const competitionLoserId = this.currentMatch && this.currentMatch.loser ? this.currentMatch.loser : 0;
        if (competitionLoserId) {
          loser = this.player1.user_id === competitionLoserId ? this.player1 : this.player2;
        }

        return loser;
      },
      winnerResult(): number {
        let winnerResult = null;
        if (this.isMinigame) {
          if (this.competitionWinner) {
            winnerResult = this.player1.user_id === this.competitionWinner.user_id
              ? this.currentMatch.result_1
              : this.currentMatch.result_2;
          } else if (this.isDraw) {
            winnerResult = this.currentMatch.result_1;
          }
        }

        return winnerResult;
      },
      loserResult(): number {
        let loserResult = null;
        if (this.isMinigame) {
          if (this.competitionLoser) {
            loserResult = this.player1.user_id === this.competitionLoser.user_id
              ? this.currentMatch.result_1
              : this.currentMatch.result_2;
          } else if (this.isDraw) {
            loserResult = this.currentMatch.result_2;
          }
        }

        return loserResult;
      },
      userGames(): Array<any> {
        return this.$store.state.user.games;
      },
      isCompetitionProposalRematchPending():boolean {
        const statusId = this.competitionProposalRematch ? this.competitionProposalRematch.status_id : null;

        return statusId === COMPETITION_PROPOSAL_STATUS_ID.PENDING;
      },
      isCompetitionProposalRematchAccepted():boolean {
        const statusId = this.competitionProposalRematch ? this.competitionProposalRematch.status_id : null;

        return statusId === COMPETITION_PROPOSAL_STATUS_ID.ACCEPTED;
      },
      isCompetitionProposalRematchRejected():boolean {
        const statusId = this.competitionProposalRematch ? this.competitionProposalRematch.status_id : null;

        return statusId === COMPETITION_PROPOSAL_STATUS_ID.REJECTED;
      },
      isCompetitionProposalRematchOwner():boolean {
        const ownerId = this.competitionProposalRematch ? this.competitionProposalRematch.owner.user_id : null;

        return ownerId === this.userId;
      },
      isMinigame(): boolean {
        return Boolean(this.competition.minigame);
      },
      hasPlayedMinigame(): boolean {
        let hasPlayedMinigame = this.currentMatch ? Boolean(this.currentMatch.winner) : true;
        if (!hasPlayedMinigame && this.minigameResult) {
          if (this.isPlayer1) {
            hasPlayedMinigame = this.minigameResult && this.minigameResult.player_1_match_id !== null;
          } else if (this.isPlayer2) {
            hasPlayedMinigame = this.minigameResult && this.minigameResult.player_2_match_id !== null;
          }
        } else {
          hasPlayedMinigame = true;
        }

        return hasPlayedMinigame;
      },
      showCompetitionResult(): boolean {
        let showCompetitionResult = this.competitionStatusId &&
          this.currentMatch;

        if (showCompetitionResult) {
          if (this.isMinigame) {
            showCompetitionResult = this.isDesktop && (this.competitionWinner || this.isDraw);
          } else {
           showCompetitionResult = (this.isCompetitionStarted || this.isCompetitionReview || this.isCompetitionFinished)
             && (this.isDesktop || !this.competitionWinner);
          }
        }

        return showCompetitionResult;
      },
      isDraw(): boolean {
        return this.isCompetitionCancelled
          && this.currentMatch
          && this.currentMatch.result_1 >= 0
          && this.currentMatch.result_1 === this.currentMatch.result_2;
      },
    },
    methods: {
      getPlatformIcon,
      async getCompetitionResult() {
        try {
          const {data} = await this.$http.competition.getCompetitionResult(this.competitionId, this.currentMatch.match_id, this.isManualResultCompetition, this.isMinigame);
          const {result, manualResult, minigameResult} = data.data;
          if (this.isManualResultCompetition && this.manualResult !== null) {
            this.onReloadCompetitionData();
          } else if (this.isMinigame && this.minigameResult !== null) {
            this.onReloadCompetitionData();
          }

          this.manualResult = manualResult || null;
          this.minigameResult = minigameResult || null;
          const hasWinner = result && result.winner;
          const competitionEnded = !(this.isCompetitionStarted || this.isCompetitionReview);
          if (hasWinner || competitionEnded) {
            this.clearAutoRefreshInterval();
            if (this.isManualResultCompetition && this.manualResult === null) {
              this.onReloadCompetitionData();
            } else if (this.isMinigame && this.minigameResult === null) {
              this.onReloadCompetitionData();
            }
          }
        } catch (e) {
          // Nothing to do
        }
      },
      async postCompetitionManualResult(data: Record<string, any>) {
        if (this.isManualResultCompetition && (!this.resultButtonsDisabled || this.showClaimResultButtons)) {
          try {
            this.showLoader(true);
            await this.$http.competition.postCompetitionManualResult(this.competitionId, this.currentMatch.id, data);
            this.initAutoRefreshInterval();
            if (!this.showClaimResultButtons) {
              showInterstitial();
            }
            this.showLoader(false);
          } catch ({response}) {
            const errorData = response?.data || {};
            const errorMessage = errorData?.message || errorData.errors || '';

            if (response?.status !== 202) {
              this.showToastError(this.$t('139'), errorMessage);
            }
            this.showLoader(false);
          }
        }
      },
      async cancelCompetition() {
        try {
          this.showLoader(true);
          await this.$http.competition.postCancelCompetition(this.competitionId, this.userId);
          this.showLoader(false);
          this.onReloadCompetitionData();
          this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
          this.showToastSuccess(this.$t('146'), this.$t('147'));
          showInterstitial();
        } catch ({response}) {
          this.showLoader(false);
          this.showToastError(this.$t('139'), this.$t('140'));
        }
      },
      async registerUserToCompetition() {
        this.forceDisabledButton = true;
        this.showLoader(true);
        try {
          await this.$http.user.postUserCompetition(this.userId, this.competitionId, {use_competition_free_inscription: this.useCompetitionFreeInscription, promo_inscription: 0});
          this.onRegisterUserToCompetitionSuccess();
        } catch ({response}) {
          const errorData = response?.data || {};
          const key = errorData?.key;
          const {ALREADY_REGISTERED, NO_MONEY} = RegisterCompetitionErrorKey;
          if (key === ALREADY_REGISTERED) {
            this.onRegisterUserToCompetitionSuccess();
          } else if (key === NO_MONEY) {
            this.showPopupNoMoney(this.competition.cost_inscription);
          } else {
            const errorMessage = errorData?.message || errorData?.errors || this.$t('140');
            this.showToastError(this.$t('139'), errorMessage);
          }
          this.forceDisabledButton = false;
        } finally {
          this.showLoader(false);
        }
      },
      onRegisterUserToCompetitionSuccess() {
        this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
        this.$store.dispatch(NotificationCenterActionTypes.UPDATE_NOTIFICATION_CENTER);
        let award = null;
        if (this.useCompetitionFreeInscription) {
          award = this.competitionFreeInscriptionAward;
        } else {
          if (this.userAward) {
            award = this.userAward;
          } else {
            award = (this.competition.total_awards || 0);
          }
        }

        const message = this.$createElement(PwaCreateMatchSuccess, {
          props: {
            gameName: this.competition.game_name,
            platformName: this.competition.platform,
            regionName: this.competition.region,
            award,
            awardConverted: false,
            costInscription: this.competition.cost_inscription,
            isCreated: false,
            isCompetitionFreeInscription: Boolean(this.useCompetitionFreeInscription),
          },
        });

        const title = this.isWager ? this.$t('149') : this.$t('380');
        this.showModal({
          title,
          hideCancel: true,
          message,
          contentClass: 'create-match-success-background',
          headerClass: 'title-centered',
          onOkClick: () => {
            this.onReloadCompetitionData();
            showInterstitial();
          },
        });
      },
      onRegisterCompetitionClick(useCompetitionFreeInscription = 0) {
        if (this.termsAndConditionsAccepted) {
          if (!this.isLoggedIn) {
            this.goToSignup();
          } else {
            // Comprobamos si tiene saldo
            if (!useCompetitionFreeInscription && this.walletQuantity < this.competition.cost_inscription) {
              this.showPopupNoMoney(this.competition.cost_inscription);
            } else if (this.needsConfigureGame()) {
              this.showPopupAddGame();
            } else {
              this.useCompetitionFreeInscription = useCompetitionFreeInscription;
              this.registerUserToCompetition();
            }
          }
        } else {
          this.showToastError(this.$t('139'), this.$t('151'));
        }
      },
      showPopupNoMoney(costInscription: number) {
        const message = this.$createElement(PwaInscriptionNoMoney, {
          props: {
            create: false,
            costInscription,
          },
        });
        this.showModal({
          id: 'modal-no-money',
          message,
          showOnRoot: true,
          contentClass: {'no-money': true, 'is-desktop': this.isDesktop, 'hide-footer': true},
        });
      },
      showPopupAddGame() {
        this.showModalById(MODAL_IDS.ADD_GAME, {defaultRelatedGameId: this.competition.related_game_id, currentPage: true});
      },
      showRegisterUserToCompetition() {
        const inscriptionConfirmationModal: any = this.$refs.inscriptionConfirmationModal;
        inscriptionConfirmationModal.$refs.modal.show();
      },
      onCancelCompetitionClick() {
        if (this.isCompetitionPending && this.isOwner) {
          this.showModal({
            title: this.$t('334'),
            message: this.$t('152'),
            onOkClick: this.cancelCompetition,
          });
        }
      },
      onCountDownFinished() {
        this.hasTimeLeft = false;
      },
      clearAutoRefreshInterval(): void {
        if (this.autoRefreshInterval) {
          clearInterval(this.autoRefreshInterval);
          this.autoRefreshInterval = null;
        }
      },
      initAutoRefreshInterval(): void {
        this.clearAutoRefreshInterval();
        if (this.currentMatch && this.currentMatch.match_id) {
          this.getCompetitionResult();
        }
        this.autoRefreshInterval = setInterval(() => {
          if (this.currentMatch && this.currentMatch.match_id) {
            this.getCompetitionResult();
          } else {
            this.onReloadCompetitionData();
          }
        }, 30000);
      },
      restartAutoRefreshInterval(): void {
        if (!this.isCompetitionFinished && !this.isCompetitionCancelled) {
          this.initAutoRefreshInterval();
        } else {
          this.clearAutoRefreshInterval();
        }
      },
      updateSelectedTab(): void {
        if (this.isTournament) {
          if (this.currentMatchId) {
            this.selectedTab = this.tabs.AWARDS;
          } else if (this.isUserInCompetition) {
            this.selectedTab = this.tabs.ROUNDS;
          } else {
            this.selectedTab = this.isCompetitionPending || this.isCompetitionConfirmed ? this.tabs.AWARDS : this.tabs.ROUNDS;
          }
        }
      },
      goToUserProfile(username: string) {
        if (username) {
          this.routerPush(ROUTES.profile.name, {username});
        }
      },
      getUserNamePath(username: string) {
        const routeLang = this.routeLang ? `/${this.routeLang}/` : '/';
        return username
          ? `${routeLang}${ROUTES.profile.path.replace(':username', encodeURIComponent(username))}`
          : '#';
      },
      goToChat() {
        const params: any = {
          competitionId: this.competitionId,
          matchId: this.currentMatchId,
        };

        this.routerPush(ROUTES.competitionChat.name, params);
      },
      hasGameConfigured() {
        let isGameConfigured = false;
        if (this.competition.related_game_id) {
          if (this.userGames.find((game: any) => this.competition.related_game_id === game.game_id && game.validated === 1)) {
            isGameConfigured = true;
          }
        }

        return isGameConfigured;
      },
      needsConfigureGame() {
        return this.competition.game_needs_validation && !this.hasGameConfigured();
      },
      rematchClick() {
        const opponentPlayer = this.isPlayer1 ? this.player2 : this.player1;
        this.createMatch({
          gameAlias: this.competition.game_alias,
          gameConfigured: true,
          competitionProposal: {
            username: opponentPlayer.username,
            competitionId: this.competitionId,
            gameId: this.competition.game_id,
          },
        });
      },
      onCompetitionProposalResponse(accepted: number) {
        if (accepted) {
          if (this.walletQuantity < this.competitionProposalRematch.cost_inscription && !this.competitionProposalRematch.competition_free_inscription_id) {
            this.showPopupNoMoney(this.competitionProposalRematch.cost_inscription);
          } else {
            this.showAcceptCompetitionProposalRematchModal();
          }
        } else {
          this.showRejectCompetitionProposalRematchModal();
        }
      },
      showAcceptCompetitionProposalRematchModal() {
        const acceptCompetitionProposalModal: any = this.$refs.acceptCompetitionProposalModal;
        acceptCompetitionProposalModal.$refs.modal.show();
      },
      showRejectCompetitionProposalRematchModal() {
        this.showModal({
          title: this.$t('486'),
          message: this.$t('487'),
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          showOnRoot: true,
          onOkClick: () => this.postCompetitionProposalRematchResponse(0),
        });
      },
      showCancelCompetitionProposalRematchModal() {
        this.showModal({
          title: this.$t('495'),
          message: this.$t('496'),
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          showOnRoot: true,
          onOkClick: this.postCompetitionProposalRematchCancel,
        });
      },
      goToRematch() {
        if(this.competitionProposalRematch && this.competitionProposalRematch.competition_id) {
          this.$router.push({name: ROUTES.competition.name, params: {lang: this.routeLang, competitionId: this.competitionProposalRematch.competition_id}});
        }
      },
      async postCompetitionProposalRematchResponse(accepted: number, competitions_free_inscriptions_id = 0) {
        try {
          if (!competitions_free_inscriptions_id && this.walletQuantity < this.competitionProposalRematch.cost_inscription) {
            this.showPopupNoMoney(this.competitionProposalRematch.cost_inscription);
          }else{
            this.showLoader(true);
            const {data} = await this.$http.competition.postCompetitionProposalResponse(this.competitionProposalRematch.id, {accepted, competitions_free_inscriptions_id});
            this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
            this.$store.dispatch(NotificationCenterActionTypes.UPDATE_NOTIFICATION_CENTER);
            this.showLoader(false);
            if (accepted) {
              this.competitionProposalRematch.competition_id = data.data.competition_id;
              const competitionFreeInscriptionUserAward = competitions_free_inscriptions_id ? this.competitionProposalRematch.competition_free_inscription_user_award : null;
              this.showRematchSuccessModal(competitionFreeInscriptionUserAward);
            } else {
              this.onReloadCompetitionData();
            }
          }
        } catch ({response}) {
          const errorData = response?.data || {};
          const errorMessage = errorData?.message || errorData?.errors || this.$t('140');
          this.showLoader(false);
          this.showToastError(this.$t('139'), errorMessage);
        }
      },
      async postCompetitionProposalRematchCancel() {
        try {
          this.showLoader(true);
          await this.$http.competition.postCompetitionProposalCancel(this.competitionProposalRematch.id);
          this.onReloadCompetitionData();
        } catch ({response}) {
          const errorData = response?.data || {};
          const errorMessage = errorData?.message || errorData?.errors || this.$t('140');
          this.showLoader(false);
          this.showToastError(this.$t('139'), errorMessage);
        }
      },
      showRematchSuccessModal(competitionFreeInscriptionUserAward: number = null) {
        const message = this.$createElement(PwaCreateMatchSuccess, {
          props: {
            gameName: this.competitionProposalRematch.game_name,
            platformName: this.competitionProposalRematch.platform_name,
            regionName: this.competitionProposalRematch.region,
            award: competitionFreeInscriptionUserAward ? competitionFreeInscriptionUserAward : this.competitionProposalRematch.userAward,
            awardConverted: false,
            costInscription: this.competitionProposalRematch.cost_inscription,
            isCompetitionFreeInscription: Boolean(competitionFreeInscriptionUserAward),
            isCreated: false,
          },
        });

        this.showModal({
          title: this.$t('141'),
          hideCancel: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          message,
          showOnRoot: true,
          contentClass: 'create-match-success-background',
          headerClass: 'title-centered',
          onOkClick: () => {
            if (this.competitionProposalRematch.competition_id) {
              this.routerPush(ROUTES.competition.name, {competitionId: this.competitionProposalRematch.competition_id});
            } else {
              this.onReloadCompetitionData();
            }

            showInterstitial();
          },
        });
      },
      onPlayMinigameClick() {
        if (!this.hasPlayedMinigame) {
          if(this.isDesktop) {
            const message = this.$createElement(PwaPlayGame, {
              props: {
                isModal: true,
                gameAlias: this.competition.game_alias,
                competitionId: this.competitionId,
                resultId: this.currentMatch.id,
              },
            });
            const title = this.$t('549');
            this.showModal({
              id: MODAL_IDS.MODAL_PLAY_GAME,
              title,
              message,
              hideCancel: true,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              dialogClass: 'game-container',
              headerClass: 'title-centered',
              contentClass: 'create-match-background',
              footerClass: 'buttons-hidden',
            });
          } else {
            const params: Record<string, string> = {
              gameAlias: this.competition.game_alias,
              competitionId: this.competitionId.toString(),
              resultId: this.currentMatch.id,
            };
            this.$router.push({name: ROUTES.playGame.name, params: {lang: this.routeLang, ...params}});
          }
        }
      }
    },
    watch: {
      competitionId() {
        this.restartAutoRefreshInterval();
        this.updateSelectedTab();
      },
      currentMatchId() {
        this.restartAutoRefreshInterval();
        this.updateSelectedTab();
      },
    }
  });
